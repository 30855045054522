import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { UfinetButton, UfinetModal } from 'ufinet-web-components'
import { useModal, useTranslator } from 'ufinet-web-functions'
import { InvoiceTypes } from '../../types/invoice/InvoiceTypes'
// import styled from ''

type TestCollapseProps = {
	title: string | null
	data: InvoiceTypes
}

export const CollapseRender = ({ title, data }: TestCollapseProps): JSX.Element => {
	const [modal, showModal, hideModal] = useModal()

	const NOSENTSTATUS = 'NOSENT'
	const translate = useTranslator()

	const styles = {
		width: '125px',
		lineHeight: '17px',
	}

	return (
		<>
			{data.status === NOSENTSTATUS ? (
				<UfinetButton
					className="btn-danger"
					style={styles}
					content={title ? translate(title) : ''}
					onClick={showModal}
				/>
			) : (
				<UfinetButton style={styles} content={title ? translate(title) : ''} onClick={showModal} />
			)}
			<UfinetModal size="lg" title={title ? translate(title) : ''} show={modal} handleClose={hideModal}>
				<div className="container">
					<div className="row">
						<div className="col">
							<p className="fw-bolder">{translate('SHIPPING.DAY')}</p>
						</div>
						<div className="col">
							<p className="fw-bolder">{translate('SENDTO')}</p>
						</div>
						<div className="col">
							<p className="fw-bolder">{translate('SENDTOCC')}</p>
						</div>
						<div className="col">
							<p className="fw-bolder">{translate('ERRORS')}</p>
						</div>
					</div>
					<div className="row">
						<div className="col-3 text-break">
							<div>{data.sendDate}</div>
						</div>

						<div className="col-3 text-break">
							<div>{`${data.sendTo}`}</div>
						</div>
						<div className="col-3 text-break">
							<div>{`${data.sendToCC}`}</div>
						</div>

						<div className="col-3 text-break">
							<div>{data.errorDescription}</div>
						</div>
						{/* <div className='col'>
              <div>{data.partnershipCode}</div>
            </div> */}
					</div>
				</div>
			</UfinetModal>
		</>
	)
}
