import * as ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import 'ufinet-web-components/dist/index.css'

import './sass/style.scss'

import { QueryClient, QueryClientProvider } from 'react-query'

import { GfaI18nProvider } from 'components/common/i18n/GfaI18nProvider'
import { Provider } from 'react-redux'
import { AppRoutes } from 'routing/AppRoutes'
import { AuthProvider } from 'ufinet-web-components'
import packageJson from '../package.json'
import { store } from './store/store'

// Start the mocking conditionally
if (process.env.REACT_APP_ENV === 'TEST') {
	const { worker } = require('./mocks/browser')
	worker.start()
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
})

console.info(packageJson.version)

ReactDOM.render(
	<>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AuthProvider>
					<GfaI18nProvider>
						<AppRoutes />
					</GfaI18nProvider>
				</AuthProvider>
			</Provider>
		</QueryClientProvider>
	</>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
