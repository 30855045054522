import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { UfinetButton, UfinetModal } from 'ufinet-web-components'
import { useModal, useTranslator } from 'ufinet-web-functions'
import { InvoiceTypes } from '../../types/invoice/InvoiceTypes'
import axios from 'axios'
import * as XLSX from 'xlsx'
// import styled from ''

type DocumentsProps = {
	data: InvoiceTypes
}

export const DocumentsRender = ({ data }: DocumentsProps): JSX.Element => {
	const [modal, showModal, hideModal] = useModal()

	const isDocuments = data && data.documents

	const translate = useTranslator()

	const styles = {
		width: '125px',
		lineHeight: '17px',
	}

	const handleExcelFile = async (url: string) => {
		try {
			const response = await axios.get(url, {
				responseType: 'arraybuffer',
			})
			const data = new Uint8Array(response.data)
			const workbook = XLSX.read(data, { type: 'array' })
			return workbook
		} catch (error) {
			console.error('Error fetching the Excel file:', error)
			return null
		}
	}

	const openExcelInNewTab = async () => {
		try {
			const documentTitle = data?.documents?.xlsx[0]?.title.split('.')[0]
			const workbook = await handleExcelFile(data?.documents?.xlsx[0].url)

			if (workbook) {
				const newWindow = window.open('', '_blank')
				if (newWindow) {
					newWindow.document.open()
					newWindow.document.write(`
            <html>
              <head>
                <title>Excel File</title>
                <style>
                  body { font-family: Arial, sans-serif; margin: 20px; }
                  h3 { margin-top: 20px; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  table, th, td { border: 1px solid black; }
                  th, td { padding: 8px; text-align: left; }
                </style>
              </head>
              <body>
              <h1>${documentTitle}</h1>
          `)

					workbook.SheetNames.map((sheetName, index) => {
						const worksheet = workbook.Sheets[sheetName]
						const html = XLSX.utils.sheet_to_html(worksheet, { id: sheetName })
						newWindow.document.write(`<h3>Sheet ${index + 1}: ${sheetName}</h3>`)
						newWindow.document.write(html)
					})

					newWindow.document.write('</body></html>')
					newWindow.document.close()
				}
			}
		} catch (error) {
			console.error('Error processing the Excel file:', error)
		}
	}

	const openPDFsInNewTab = async () => {
		try {
			const pdfContents = await Promise.all(
				data.documents.pdf.map(async (file) => {
					const response = await axios.get(file.url, {
						responseType: 'blob',
					})
					const blob = new Blob([response.data], { type: 'application/pdf' })
					const blobUrl = URL.createObjectURL(blob)
					return { url: blobUrl, title: file.title.split('.')[0] }
				})
			)

			const newWindow = window.open('', '_blank')
			newWindow?.document.open()
			newWindow?.document.write(
				`<div>${pdfContents
					.map(
						({ url, title }, index: any) =>
							`<h3>${title}</h3><iframe src="${url}" width="100%" height="100%" title="pdf-${title}"></iframe>`
					)
					.join('<br/><br/>')}</div>`
			)
			newWindow?.document.close()
		} catch (error) {
			console.error('Error fetching the PDF files:', error)
		}
	}

	const escapeHtml = (unsafe: any) => {
		return unsafe
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;')
	}

	const openXMLInNewTab = async () => {
		try {
			const xmlContents = await Promise.all(
				data.documents.xml.map(async (file) => {
					const response = await axios.get(file.url, {
						responseType: 'text',
					})
					return { title: file.title.split('.')[0], content: response.data }
				})
			)
			const newWindow = window.open('', '_blank')
			newWindow?.document.write(
				`
        <html>
            <head>
              <title>XML Viewer</title>
              <style>
                body { font-family: Arial, sans-serif; margin: 20px; }
                h3 { margin-top: 20px; }
                pre { background-color: #f4f4f4; padding: 10px; border: 1px solid #ccc; overflow-x: auto; }
                code { white-space: pre-wrap; }
              </style>
            </head>
            <body>
              ${xmlContents
								.map(
									(xml, index) => `
                <h3>XML File ${xml.title}</h3>
                <pre><code>${escapeHtml(xml.content)}</code></pre>
              `
								)
								.join('<br/><br/>')}
            </body>
          </html>
        `
			)
			newWindow?.document.close()
		} catch (error) {
			console.error('Error fetching the XML files:', error)
		}
	}

	return (
		<>
			<UfinetButton style={styles} content={translate('COL.TOSEE')} onClick={showModal} />

			<UfinetModal size="lg" title={translate('COL.DOCUMENTS')} show={modal} handleClose={hideModal}>
				<div className="container">
					<div className="row">
						<div className="col">
							<p className="fw-bolder">{translate('COL.PDF')}</p>
						</div>
						<div className="col">
							<p className="fw-bolder">{translate('COL.XML')}</p>
						</div>
						<div className="col">
							<p className="fw-bolder">{translate('COL.XLSX')}</p>
						</div>
					</div>
					<div className="row">
						<div className="col-4 text-break">
							{isDocuments && data.documents.pdf.length > 0 && (
								<Button onClick={openPDFsInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
							)}
						</div>

						<div className="col-4 text-break">
							{isDocuments && data.documents.xml.length > 0 && (
								<Button onClick={openXMLInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
							)}
						</div>

						<div className="col-4 text-break">
							{isDocuments && data.documents.xlsx.length > 0 && (
								<Button onClick={openExcelInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
							)}
						</div>
					</div>
				</div>
			</UfinetModal>
		</>
	)
}
