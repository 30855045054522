import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { InvoicesPage } from 'pages/invoices/InvoicesPage'
import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, UserTypesEnum, useTranslator } from 'ufinet-web-functions'

export const PATH_HOME = ''
export const PATH_INVOICES = 'invoices'

export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_INVOICES, 'MENU.INVOICE'],
])

export const routerLinkMap = new Map<string, string>([
	[PATH_HOME, `/${PATH_HOME}`],
	[PATH_INVOICES, `/${PATH_INVOICES}`],
])

export const routePermissionMap = new Map<string, Authority[]>([
	[PATH_INVOICES, [Authority.gfaConfigurationModuleRead]],
])

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route path={PATH_HOME} element={<WelcomePage appName={translate('GFA.NAME')} />} />

				<Route
					path={PATH_INVOICES}
					element={
						<AuthRoute
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
							authorizedRoles={routePermissionMap.get(PATH_INVOICES)!}
						>
							<InvoicesPage />
						</AuthRoute>
					}
				/>

				<Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
