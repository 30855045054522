import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { InvoiceRepository } from '../domain/repository/InvoiceRepository'

const url = `${process.env.REACT_APP_URL_BACK_GFA}invoice`

export const HttpInvoiceRepository: (authData: IAuthData) => InvoiceRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async (invoiceFindRequest, options) =>
			await FetchAPI.get_params(url + '/find', invoiceFindRequest, { ...fetchOptions, ...options }),
	}
}
