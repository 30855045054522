export interface EntitiesTypes {
	partnershipId: string
	partnershipName: string
}

export interface InvoiceTypes {
	notificationid: string
	invoiceId: string
	invoiceReference: string
	customerName: string
	invoicingGroupId: string
	invoicingGroupName: string
	invoiceType: string
	invoiceSuffix: string
	partnershipCode: string
	invoiceDate: Date
	status: string
	sendDate: Date
	sendTo: string
	sendToCC: string
	folderPath: string
	errorDescription: string
	documents: Documents
}

interface Documents {
	pdf: { url: string; title: string }[]
	xml: { url: string; title: string }[]
	xlsx: { url: string; title: string }[]
}

export interface InvoiceTypesFilters {
	notificationid?: string
	invoiceId?: string
	invoiceReference?: string
	customerName?: string
	invoicingGroupId?: string
	invoicingGroupName?: string
	invoiceType?: string
	invoiceSuffix?: string
	partnershipCode?: string
	invoiceDate?: Date
	status?: string
	sendDate?: Date
	sendTo?: string
	sendToCC?: string
	folderPath?: string
	errorDescription?: string
}

export interface ErrorResponse {
	errorCode: string
	message: string
	reason?: string
}

export enum StateValues {
	NOSENT = 'NOSENT',
	PENDING_DOCUMENTS = 'PENDINGDOCUMENTS',
	PROCESS_SENT = 'PROCESSSENT',
	SENDING = 'SENDING',
	SENT = 'SENT',
	RESENT = 'RESENT',
	FAILED = 'FAILED',
}

export const StateMappings = new Map<StateValues, string>([
	[StateValues.NOSENT, 'NOSENT'],
	[StateValues.PENDING_DOCUMENTS, 'PENDINGDOCUMENTS'],
	[StateValues.PROCESS_SENT, 'PROCESSSENT'],
	[StateValues.SENDING, 'SENDING'],
	[StateValues.SENT, 'SENT'],
	[StateValues.RESENT, 'RESENT'],
	[StateValues.FAILED, 'FAILED'],
])
