import { HttpLegalEntityRepository } from 'modules/legalEntity/infrastructure/HttpLegalEntityRepository'
import { FC, Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
	DatePicker,
	DatePickerTypeEnum,
	ISelect,
	IUfinetSelectOption,
	UfinetInput,
	UfinetSelect,
	UfinetSelectHandle,
} from 'ufinet-web-components'
import { AuthContext, IPagePermissions, useTranslator } from 'ufinet-web-functions'
import { InvoiceHeaderFilters, stateOptions } from '../filters/InvoiceFiltersTypes'
import { GetLegalEntities } from 'request'
import { EntitiesTypes, InvoiceTypes, InvoiceTypesFilters } from 'types/invoice/InvoiceTypes'

interface InvoicesFiltersProps {
	headerFilters: InvoiceHeaderFilters
	setHeaderFilters: (invoiceHeaderFilters: InvoiceHeaderFilters) => void
	selectLegalEntityRef?: Ref<UfinetSelectHandle>
	selectStateRef?: Ref<UfinetSelectHandle>
	permissions: IPagePermissions
}

export const InvoicesFilters: FC<InvoicesFiltersProps> = ({
	headerFilters,
	selectLegalEntityRef,
	selectStateRef,
	setHeaderFilters,
	permissions,
}) => {
	const { data: legalEntities, isLoading: isLoadingEntities, isError: isErrorEntities } = GetLegalEntities()

	const legalEntittiesSelect =
		legalEntities &&
		legalEntities?.data.map((item: EntitiesTypes) => ({
			label: item.partnershipName,
			value: item.partnershipId,
		}))

	const showErrorEntities = () => {
		toast.error(translate('INVOICES.LEGAL_ENTITY.ERROR'))
	}

	const translate = useTranslator()
	const [legalEntityOptions, setLegalEntityOptions] = useState<ISelect[]>([])
	const authData = useContext(AuthContext)
	const [loadingOptions, setLoadingOptions] = useState<boolean>(false)

	// const countryRepository = useMemo(() => HttpLegalEntityRepository(authData), [authData])
	// const getLegalEntityOptions = useCallback(async () => {
	//   if (permissions.canUpdate) {
	//     setLoadingOptions(true)
	//     try {
	//       const countrySettings = await countryRepository.findAll()
	//       const options = countrySettings.map(({ legalEntityName, dataAreaId }) => ({
	//         label: legalEntityName,
	//         value: dataAreaId,
	//       }))
	//       setLegalEntityOptions(options)
	//       setLoadingOptions(false)
	//     } catch {
	//       toast.error(translate('INVOICES.LEGAL_ENTITY.ERROR'))
	//       setLoadingOptions(false)
	//     }
	//   }
	// }, [countryRepository, permissions, translate])

	// useEffect(() => {
	//   getLegalEntityOptions()
	// }, [getLegalEntityOptions])

	const formattedDate = (date: string) => {
		const newDate = new Date(date)
		const formattedTwoDigits = (numero: number) => numero.toString().padStart(2, '0')
		const day = formattedTwoDigits(newDate.getDate())
		const month = formattedTwoDigits(newDate.getMonth() + 1)
		const year = newDate.getFullYear()

		const fullDate = `${month}/${day}/${year}`
		return fullDate
	}

	return (
		<>
			{isErrorEntities && showErrorEntities()}

			<div className="row align-items-center row-gap-3">
				<DatePicker
					className="col col-xl-4 col-md-6 col-sm-12"
					id="startDate"
					placeholder={translate('INVOICE.FILTER.INITIAL_DATE')}
					value={headerFilters.startDate}
					onChange={(e) => {
						setHeaderFilters({
							...headerFilters,
							startDate: e.value as Date,
							formattedStartDate: formattedDate(e.value as string),
						})
					}}
					type={DatePickerTypeEnum.DATE_ONLY}
					showCalendarIcon={true}
					label={translate('INVOICE.FILTER.INITIAL_DATE')}
				></DatePicker>

				<DatePicker
					className="col col-xl-4 col-md-6 col-sm-12"
					id="endDate"
					placeholder={translate('INVOICE.FILTER.FINAL_DATE')}
					value={headerFilters.endDate}
					onChange={(e) => {
						setHeaderFilters({
							...headerFilters,
							endDate: e.value as Date,
							formattedEndDate: formattedDate(e.value as string),
						})
					}}
					type={DatePickerTypeEnum.DATE_ONLY}
					showCalendarIcon={true}
					// value={headerFilters.terminationService}
					label={translate('INVOICE.FILTER.FINAL_DATE')}
				></DatePicker>

				{/* <UfinetInput
        className="col col-xl-2 col-md-6 col-sm-12"
        id="invoiceNumber"
        type="text"
        value={headerFilters.invoiceNumber || ''}
        placeholder={translate('INVOICE.FILTER.INVOICE')}
        onChange={(e) => {
          const { invoiceNumber, ...restFilters } = headerFilters
          setHeaderFilters(e.target?.value ? { ...restFilters, invoiceNumber: e.target.value } : restFilters)
        }}
        solid={false}
        labelTitle={translate('INVOICE.FILTER.INVOICE')}
      ></UfinetInput> */}

				{/* <UfinetInput
        className="col col-xl-2 col-md-6 col-sm-12"
        id="finalCustomer"
        type="text"
        value={headerFilters.finalCustomer || ''}
        placeholder={translate('INVOICE.FILTER.CLIENT')}
        onChange={(e) => {
          const { invoiceNumber, ...restFilters } = headerFilters
          setHeaderFilters(e.target?.value ? { ...restFilters, finalCustomer: e.target.value } : restFilters)
        }}
        solid={false}
        labelTitle={translate('INVOICE.FILTER.CLIENT')}
      ></UfinetInput> */}

				{permissions.canUpdate && (
					<UfinetSelect
						className="col col-xl-4 col-md-6 col-sm-12"
						id="dataAreaId"
						placeholder={translate('INVOICE.FILTER.LEGAL_ENTITY')}
						labelTitle={translate('INVOICE.FILTER.LEGAL_ENTITY')}
						// value={}
						shouldClearDefaultValue
						options={legalEntittiesSelect}
						onChange={(value) => {
							console.log('dataAreaId', (value as IUfinetSelectOption).value)
							setHeaderFilters({
								...headerFilters,
								dataAreaId: (value as IUfinetSelectOption).value,
							})
						}}
						closeMenuOnSelect={true}
						ref={selectLegalEntityRef}
						isLoadingOptions={isLoadingEntities}
						isSearchable={false}
					></UfinetSelect>
				)}
				{/* <UfinetSelect
        className="col col-xl-2 col-md-6 col-sm-12"
        id="state"
        placeholder={translate('INVOICE.FILTER.STATE')}
        labelTitle={translate('INVOICE.FILTER.STATE')}
        options={stateOptions(translate)}
        onChange={(value) => {
          setHeaderFilters({ ...headerFilters, invoiceState: (value as IUfinetSelectOption).value })
        }}
        closeMenuOnSelect={true}
        ref={selectStateRef}
        isSearchable={false}
      ></UfinetSelect> */}
			</div>
		</>
	)
}
