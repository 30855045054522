async function waitForLocalStorage(key: string) {
	return new Promise((resolve, reject) => {
		let interval = setInterval(() => {
			if (localStorage.getItem(key)) {
				// @ts-ignore
				const data = JSON.parse(localStorage.getItem(key))
				const isUserData = data.userData ? true : false
				if (isUserData) {
					clearInterval(interval)
					resolve(localStorage.getItem(key))
				}
			}
		}, 100) // check every 100 milliseconds
	})
}

const getUsername = async () => {
	try {
		const localStorageData = await waitForLocalStorage('ufinet-auth')
		// @ts-ignore
		const formattedData = JSON.parse(localStorageData)
		const accessToken = formattedData.token.accessToken
		const username = formattedData.userData.username
		return {
			username,
			accessToken,
		}
	} catch (error) {
		throw error
	}
}

export { getUsername }
