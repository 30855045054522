import axios from 'axios'
import { useQuery, useMutation } from 'react-query'
import { fetchGET, fetchPOST } from 'utils/https/https'

export const GetLegalEntities = () => {
	return useQuery(['GetLegalEntities'], async () => {
		try {
			const response = await fetchGET('notification/getLegalEntities')
			return response
		} catch (error) {
			throw error
		}
	})
}

export const GetInvoices = (data: any) => {
	const dataAreaId = data && data.dataAreaId ? data.dataAreaId : null
	const startDate = data && data?.formattedStartDate ? data.formattedStartDate : null
	const status = data && data?.status ? data.status[0] : null
	const endDate = data && data.formattedEndDate ? data.formattedEndDate : null
	const customerName = data && data.customerName ? data.customerName : null
	const invoicingGroupId = data && data.invoicingGroupId ? data.invoicingGroupId : null
	const invoiceReference = data && data.invoiceReference ? data.invoiceReference : null

	const pageNumber = data?.pageNumber
	const pageSize = data?.pageSize
	const sortOrder = data?.sortOrder ? data.sortOrder : null
	const sortField = data?.sortField ? data.sortField : null

	return useQuery(
		[
			'GetInvoices',
			dataAreaId,
			startDate,
			endDate,
			status,
			customerName,
			invoicingGroupId,
			invoiceReference,
			pageNumber,
			pageSize,
			sortOrder,
			sortField,
		],
		async () => {
			try {
				const searchParams = new URLSearchParams()

				if (dataAreaId) {
					searchParams.append('dataAreaId', dataAreaId)
				}

				if (startDate) {
					searchParams.append('startDate', startDate)
				}

				if (endDate) {
					searchParams.append('endDate', endDate)
				}

				if (status) {
					searchParams.append('status', status)
				}

				if (customerName) {
					searchParams.append('customerName', customerName)
				}

				if (invoicingGroupId) {
					searchParams.append('invoicingGroupId', invoicingGroupId)
				}

				if (invoiceReference) {
					searchParams.append('invoiceReference', invoiceReference)
				}

				//* Pagination
				if (pageNumber) {
					searchParams.append('pageNumber', pageNumber)
				}

				if (pageSize) {
					searchParams.append('pageSize', pageSize)
				}

				if (sortOrder) {
					searchParams.append('sortOrder', sortOrder)
				}

				if (sortField) {
					searchParams.append('sortField', sortField)
				}

				const response = await fetchGET(`notification/getInvoiceNotifications?&${searchParams.toString()}`)
				return response
			} catch (error) {
				throw error
			}
		},
		{
			enabled: !!dataAreaId,
		}
	)
}

const SendInvoices = async (data: any) => {
	try {
		const response = await fetchPOST('notification/sendMail', data)
		return response
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			const customErr = {
				errorCode: error.response.data.code,
				message: error.response.data.message,
				reason: error.response.data.reason,
			}

			throw customErr
		}
	}
}

export const UsePostSendInvoices = (cbSuccess: any) => {
	return useMutation(SendInvoices, {
		onSuccess: (data) => {
			cbSuccess(data)
		},
	})
}
