import { InvoiceLog, InvoiceLogs } from 'modules/invoice/domain/InvoiceLog'
import { ColData } from 'ufinet-web-components'
import { InvoiceTypes, StateMappings, StateValues } from '../../types/invoice/InvoiceTypes'
import { useTranslator } from 'ufinet-web-functions'
import { CollapseRender, DocumentsRender } from 'lib'

export const mkColsInvoice = (translate: (id: string) => string): ColData[] => [
	{
		field: 'invoiceReference',
		header: 'COL.INVOICE.DOCUMENT',
		width: '250px',
		nonSortable: false,
		nonFilterable: false,
		filterType: 'text',
		// body: (row: InvoiceLogs) => row.invoiceReference || '-',
	},
	{
		field: 'customerName',
		header: 'COL.CUSTOMERNAME',
		width: '250px',
		nonSortable: false,
		nonFilterable: false,
		filterType: 'text',
		// body: (row: InvoiceLogs) => row.customerName || '-',
	},
	{
		field: 'invoicingGroupId',
		header: 'COL.INVOICINGGROUP',
		width: '250px',
		nonSortable: false,
		nonFilterable: false,
		filterType: 'text',
		// body: (row: InvoiceLogs) => row.invoicingGroupId || '-',
	},
	{
		field: 'invoiceDate',
		header: 'COL.INVOICEDATE',
		width: '250px',
		nonSortable: false,
		nonFilterable: true,
		// body: (row: InvoiceLogs) => row.invoiceDate || '-',
	},
	{
		field: 'status',
		header: 'COL.STATUS',
		width: '250px',
		nonSortable: false,
		nonFilterable: false,
		filterType: 'select',
		filterOptions: [
			{ label: translate(StateMappings.get(StateValues.NOSENT) ?? ''), value: StateValues.NOSENT },
			{ label: translate(StateMappings.get(StateValues.FAILED) ?? ''), value: StateValues.FAILED },
			{
				label: translate(StateMappings.get(StateValues.PENDING_DOCUMENTS) ?? ''),
				value: StateValues.PENDING_DOCUMENTS,
			},
			{ label: translate(StateMappings.get(StateValues.PROCESS_SENT) ?? ''), value: StateValues.PROCESS_SENT },
			{ label: translate(StateMappings.get(StateValues.RESENT) ?? ''), value: StateValues.RESENT },
			{ label: translate(StateMappings.get(StateValues.SENDING) ?? ''), value: StateValues.SENDING },
			{ label: translate(StateMappings.get(StateValues.SENT) ?? ''), value: StateValues.SENT },
		],
		body: (row: InvoiceLogs) => <CollapseRender title={row.status} data={row} />,
	},
	{
		field: 'documents',
		header: 'COL.VISUALIZE',
		width: '250px',
		nonSortable: false,
		nonFilterable: true,
		body: (row: InvoiceLogs) => <DocumentsRender data={row} />,
	},
]
