import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { TriggerRepository } from '../domain/repository/TriggerRepository'

const url = `${process.env.REACT_APP_URL_BACK_GFA}api/trigger`

export const HttpTriggerRepository: (authData: IAuthData) => TriggerRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		resend: async (invoicesTriggerRequest, options) =>
			await FetchAPI.post(url + '/resend', invoicesTriggerRequest, { ...fetchOptions, ...options }),
	}
}
