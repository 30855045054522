/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'

import { PATH_INVOICES, routerNameMap } from '../../routing/protected/PrivateRoutes'

import { faCircleDot, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser, useTranslator } from 'ufinet-web-functions'

enum AsideMenu {
	NONE,
	CONFIGURATION,
}

const AsideMenuMain: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION

	const roles = userData?.authorities || []

	const configPermissions = Authority.getGfaConfigPermissions(roles)
	const isInternal = useInternalUser()

	const menuConfigurationItems: IMenuItem[] = [
		{ to: PATH_INVOICES, title: routerNameMap.get(PATH_INVOICES), icon: faCircleDot },
	].map((it) => ({ ...it }))

	return (
		<>
			{configPermissions.canRead && isInternal && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faFileInvoice} className="fs-2x" />}
					title={translate('MENU.BILLING')}
					active={isConfigurationActive()}
				>
					{menuConfigurationItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.CONFIGURATION)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
